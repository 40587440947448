import React, { useContext, useEffect } from "react";
import FolderButtons from "../components/folder/FolderButtons";
import AdjuntoCard from "../components/folder/AdjuntoCard";
import FolderIcon from "../components/folder/FolderIcon";
import Breadcrumb from "../components/common/Breadcrumb";
import { FolderContext } from "../context/FolderContext";
import { MenuContext } from "../context/MenuContext";
import { notCliente } from "../utils/permisos";
import useProyecto from "../hooks/useProyecto";
import { CapacitorContext } from "../context/CapacitorContext";

const Folder = ({ idFolder, hideFolderButton }) => {
  const { idProyecto, proyecto } = useProyecto();
  const {
    setButtons,
    setSelected,
    selected,
    setTitle,
    setSubtitle,
    setHandleClickTitle,
  } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);
  const { folder, getFolder, clearFolder, pushBreadcrumb, setBreadcrumb } =
    useContext(FolderContext);

  useEffect(() => {
    return () => {
      setButtons(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected === "supervision") setTitle("Supervisión");
    if (selected === "catalogo") setTitle("Catálogo");
    if (selected === "proyecto") setTitle("Proyecto");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    fetchFolder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFolder]);

  useEffect(() => {
    if (folder !== null && proyecto !== null) {
      setSelected({ title: folder.nombre });
      if (notCliente(proyecto) && platform !== "web") {
        setButtons(
          <FolderButtons
            idProyecto={idProyecto}
            fetchFolder={fetchFolder}
            hideFolderButton={hideFolderButton}
          />
        );
        setHandleClickTitle(fetchFolder);
      }
      if (folder?.root === true) {
        setSubtitle(null);
        setBreadcrumb([folder]);
      }
      if (folder?.root === false) {
        setSubtitle(folder.nombre);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder, proyecto]);

  const fetchFolder = () => {
    clearFolder();
    getFolder(idProyecto, idFolder);
  };

  const handlePushBreadcrumb = (currentFolder) => {
    if (!currentFolder.root) {
      pushBreadcrumb(currentFolder);
    }
    clearFolder();
  };

  const renderCarpetas = () => {
    if (folder && folder !== null) {
      return folder.subfolders.map((currentFolder) => (
        <FolderIcon
          folder={currentFolder}
          key={currentFolder.idFolder}
          idProyecto={idProyecto}
          editable={currentFolder.editable}
          getParentFolder={getFolder}
          handleBreadcrumb={() => handlePushBreadcrumb(currentFolder)}
        />
      ));
    }
  };

  const renderContenido = () => {
    if (folder !== null) {
      if (folder.subfolders.length === 0 && folder.adjuntos.length === 0) {
        return <p className="mt-3">Aún no hay contenido</p>;
      }
      let carpetas = renderCarpetas();
      let adjuntos = renderAdjuntos();
      let components = carpetas.concat(adjuntos);
      return components;
    }
    return <div className="spinner-border m-3"></div>;
  };

  const renderAdjuntos = () => {
    if (folder !== null) {
      if (Array.isArray(folder.adjuntos) && folder.adjuntos.length > 0) {
        return (
          <div key={"adjuntos-container"} className="row px-2">
            {folder.adjuntos.map((adjunto) => (
              <AdjuntoCard
                adjunto={adjunto}
                key={adjunto.idAdjunto}
                focused={adjunto}
                idProyecto={idProyecto}
                idFolder={folder.idFolder}
              />
            ))}
          </div>
        );
      }
    }
  };

  const renderHeader = () => {
    if (platform === "web")
      return (
        <div className="row hide-mobile">
          <div className="col-12 col-md-6">
            <Breadcrumb idProyecto={idProyecto} />
          </div>
          <div className="col-12 col-md-6">
            <FolderButtons
              idProyecto={idProyecto}
              fetchFolder={fetchFolder}
              hideFolderButton={hideFolderButton}
            />
          </div>
        </div>
      );
  };

  return (
    <div
      className="container-fluid px-0 h-100"
      style={{ overflowY: "auto", overflowX: "hidden" }}
    >
      {renderHeader()}
      <div className="row mt-3 align-items-center">{renderContenido()}</div>
    </div>
  );
};

export default Folder;
