import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ConceptoProfitChart = ({ concepto }) => {
  const [currentProfit, setCurrentProfit] = useState(0);
  const [currentCost, setCurrentCost] = useState(0);



  useEffect(() => {
    if(concepto) {
      const currentPrice = concepto?.precio_actual;
  
      const cost = (
        (parseFloat(currentPrice?.costo_unitario) * 100) / parseFloat(currentPrice?.precio_unitario)
      ).toFixed(2);
    
      const profit = (
        ((parseFloat(currentPrice?.precio_unitario) - parseFloat(currentPrice?.costo_unitario)) /
          parseFloat(currentPrice?.precio_unitario)) * 100
      ).toFixed(2);

      setCurrentProfit(profit);
      setCurrentCost(cost);
    }
  }, [concepto]);

  return (
    <Chart
      type={"radialBar"}
      series={[currentProfit, currentCost]}
      options={{
        labels: ["Ganancia", "Costo"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        stroke: {
          lineCap: "round",
        },
        colors: ["#89d405", "#007991"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            stops: [0, 100],
            gradientToColors: ["#B3FF00", "#05D4C6"],
          },
        },
        dataLabels: {
          formatter: function (val, opts) {
            return parseFloat(val).toFixed(2);
          },
        },
        plotOptions: {
          radialBar: {
            track: {
              background: [
                currentProfit === 100 ? "#FC6767" : "#ddd",
                "#ddd",
              ],
            },
          },
        },
      }}
    />
  );
};

export default ConceptoProfitChart;
