import React from "react";
import PropTypes from "prop-types";
import { formatMonto } from "../../utils";
import moment from "moment";

const PriceRow = ({ price }) => {
  return (
    <tr>
      <td>${formatMonto(price.precio_unitario)}</td>
      <td>${formatMonto(price.importe)}</td>
      <td>{moment(price.createdAt).format('DD/MM/YYYY')}</td>
      <td>${price.diferencial}</td>
      <td>${price.costo_unitario}</td>
    </tr>
  );
};

PriceRow.propTypes = {
  price: PropTypes.shape({
    precio_unitario: PropTypes.number.isRequired,
    importe: PropTypes.number.isRequired,
    fecha: PropTypes.string.isRequired,
    diferencial: PropTypes.string.isRequired,
    costo_unitario: PropTypes.number.isRequired,
  }).isRequired,
};

export default PriceRow;
