import React from "react";
import PropTypes from "prop-types";

// Assuming PriceRow is a separate component
// Import it here if it's in a different file
import PriceRow from "./PriceRow";

const PricesTable = ({ prices }) => {
  if (!Array.isArray(prices)) {
    return <p>Error: Prop `prices` should be an array.</p>;
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light border p-2">
            <th>Precio </th>
            <th>Importe</th>
            <th>Fecha</th>
            <th>Dif</th>
            <th>Costo</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((price, index) => (
            <PriceRow key={index} price={price} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

PricesTable.propTypes = {
  prices: PropTypes.array.isRequired,
};

export default PricesTable;
