export const EDIT_CONCEPTOS_ESTIMACION = "EDIT_CONCEPTOS_ESTIMACION";
export const SET_PROPERTY_CONCEPTO = "SET_PROPERTY_CONCEPTO";
export const SET_SINGLE_CONCEPTO = "SET_SINGLE_CONCEPTO";
export const DELETE_CONCEPTO_EXTRA = "DELETE_CONCEPTO_EXTRA";
export const CREATE_CONCEPTO_EXTRA = "CREATE_CONCEPTO_EXTRA";
export const CONCEPTOS_RECIBIDOS = "CONCEPTOS_RECIBIDOS";
export const DELETE_CONCEPTO = "DELETE_CONCEPTO";
export const CREATE_CONCEPTO = "CREATE_CONCEPTO";
export const EDIT_CONCEPTOS = "EDIT_CONCEPTOS";
export const SET_SUBCONCEPTO = "SET_SUBCONCEPTO";
export const CREATE_SUBCONCEPTO = "CREATE_SUBCONCEPTO";
export const SET_LOADING = "SET_LOADING";
