import React from "react";
import PropTypes from "prop-types";
import { formatMonto } from "../../utils";

const ConceptosRow = ({ concepto }) => {
  return (
    <tr>
      <td>{concepto.nombre}</td>
      <td>{concepto.unidad}</td>
      <td>{concepto.cantidad}</td>
      <td>${formatMonto(concepto.precio_unitario)}</td>
      <td>${formatMonto(concepto.importe)}</td>
    </tr>
  );
};

ConceptosRow.propTypes = {
  concepto: PropTypes.shape({
    nombre: PropTypes.string.isRequired,
    unidad: PropTypes.string.isRequired,
    cantidad: PropTypes.number.isRequired,
    precio_unitario: PropTypes.number.isRequired,
    importe: PropTypes.number.isRequired,
  }).isRequired,
};

export default ConceptosRow;
