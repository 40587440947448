import api from "./api";

const route = "/conceptos";

export default {
  getSingleConcepto: (idProyecto, idConcepto) =>
  api.get(`${route}/${idProyecto}/${idConcepto}`),
  getConceptosContrato: (idProyecto, idContrato) =>
    api.get(`${route}/${idProyecto}/contrato/${idContrato}`),
  getConceptosEstimacion: (idProyecto, idEstimacion) =>
    api.get(`${route}/${idProyecto}/estimacion/${idEstimacion}`),
  getConceptoPrecios: (idConcepto, idProyecto) =>
    api.get(`${route}/${idProyecto}/precios/${idConcepto}`),
  postConceptos: (idProyecto, idContrato, conceptos) =>
    api.post(`${route}/${idProyecto}/contrato/${idContrato}`, {
      conceptos,
    }),
  postSubconcepto: (idProyecto, idContrato, subconcepto) =>
    api.post(`${route}/${idProyecto}/subconcepto/contrato/${idContrato}`, subconcepto),
  updateSubconcepto: (idProyecto, idContrato, subconcepto) =>
    api.put(`${route}/${idProyecto}/subconcepto/contrato/${idContrato}`, subconcepto),
  postConceptosEstimacion: (idProyecto, idContrato, idEstimacion, conceptos) =>
    api.post(
      `${route}/${idProyecto}/contrato/${idContrato}/estimacion/${idEstimacion}`,
      { conceptos }
    ),

  postConceptosEstimacionUpload: (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos
  ) =>
    api.post(
      `${route}/${idProyecto}/contrato/${idContrato}/estimacion/${idEstimacion}/upload`,
      { conceptos }
    ),
  postConceptosAutorizados: (idProyecto, idContrato, idEstimacion, conceptos) =>
    api.post(
      `${route}/${idProyecto}/contrato/${idContrato}/estimacion/${idEstimacion}/autorizado`,
      { conceptos }
    ),
  postConceptosAutorizadosUpload: (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptos
  ) =>
    api.post(
      `${route}/${idProyecto}/contrato/${idContrato}/estimacion/${idEstimacion}/autorizado/upload`,
      { conceptos }
    ),
  postConceptosAutorizadosExtra: (
    idProyecto,
    idContrato,
    idEstimacion,
    conceptosExtra
  ) =>
    api.post(
      `${route}/${idProyecto}/contrato/${idContrato}/autorizado/${idEstimacion}/extras`,
      { conceptosExtra }
    ),
  postConceptosExtra: (
    idProyecto,
    idContrato,
    idEstimacion,
    update,
    insert,
    toDelete
  ) =>
    api.post(
      `${route}/${idProyecto}/contrato/${idContrato}/estimacion/${idEstimacion}/extras`,
      {
        update,
        insert,
        toDelete,
      }
    ),
  deleteConcepto: (idProyecto, idConcepto) =>
    api.delete(`${route}/${idProyecto}/${idConcepto}`),
};
