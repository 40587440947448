import React from "react";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ConceptosContext } from "../../context/ConceptosContext";


const ConceptoHistorial = ({ idProyecto  }) => {
  const { idConcepto } = useParams();


  const {
    concepto_precios,
    getConceptoPrecios,
  } = useContext(ConceptosContext);

  useEffect(() => {
    getConceptoPrecios(idConcepto, idProyecto);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idConcepto]);

  const renderConceptos = () => {
    return concepto_precios?.map(obj => {
      const precio = obj.Precio?.precio_unitario;

      return (
        <tr key={obj.idConceptoPrecio}>
          <td>{obj.idConcepto}</td>
          <td>{obj.Concepto.clave}</td>
          <td>{precio}</td>
          <td>{0}</td>
        </tr>
      )
    })
  }
  
  return(
    <div className="container-fluid py-3 h-100 overflow-y-auto">
      <div>
        <h2>Historial de Precios</h2>
      </div>

      <div className="table-responsive card border px-3 py-3 th-border-0 ">
        <table className="table border">
          <thead className="border">
            <tr>
              <th>Id Concepto</th>
              <th>Concepto</th>
              <th>Precio</th>
              <th>Costo</th>
            </tr>
          </thead>

          <tbody>
            {renderConceptos()}
        
          </tbody>
        </table>
      </div>
      
    </div>

  )
  
}

export default ConceptoHistorial;