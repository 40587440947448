import React, { useContext } from "react";
import FilaTotales from "./FilaTotales";
import ConceptoExtra from "./ConceptoExtra";
import PartidaPreciario from "./PartidaPreciario";
import ConceptoExtraForm from "./ConceptoExtraForm";
import PreciarioHeader from "../preciario/PreciarioHeader";
import PartidaPreciarioForm from "./PartidaPreciarioForm";
import usePreciario from "../../hooks/usePreciario";
import { AppConfigContext } from "../../context/AppConfigContext";
import { EstimacionesContext } from "../../context/EstimacionesContext";

const PreciarioTable = () => {
  const { editMode, partidas, conceptos, conceptosExtra } = usePreciario();
  const { estimacion, showEstimaciones, toggleEstimaciones } =
    useContext(EstimacionesContext);
  const { authorize_estimates } = useContext(AppConfigContext);

  function renderPartidas() {
    if (Array.isArray(partidas)) {
      //Edit Partidas
      if (editMode === "partidas") {
        return partidas.map((partida) => (
          <PartidaPreciarioForm
            partida={partida}
            key={partida.idPartidaContrato}
          />
        ));
      }

      //Edit Conceptos Extra
      if (editMode === "conceptos-extra") {
        return conceptos.map((concepto) => (
          <ConceptoExtraForm
            concepto={concepto}
            key={concepto.idConceptoExtra}
          />
        ));
      }
      let components = [];

      components = partidas.map((partida) => (
        <PartidaPreciario
          partida={partida}
          estimacion={estimacion}
          key={partida.idPartidaContrato}
          showEstimaciones={showEstimaciones}
        />
      ));

      if (Array.isArray(conceptosExtra)) {
        components = [
          ...components,
          ...conceptosExtra.map((concepto) => (
            <ConceptoExtra
              showEstimaciones={showEstimaciones}
              key={`concepto-${concepto.idConceptoExtra}`}
              concepto={concepto}
            />
          )),
        ];
      }
      components.push(<FilaTotales key="totales" conceptos={conceptos} />);
      return components;
    }
  }

  return (
    <div className="table-responsive border th-border-0 align-items-top tabla-preciario">
      <table className="table mb-0">
        <PreciarioHeader
          editMode={editMode}
          showEstimaciones={showEstimaciones}
          toggleEstimaciones={toggleEstimaciones}
          authorize_estimates={authorize_estimates}
        />
        <tbody>{renderPartidas()}</tbody>
      </table>
    </div>
  );
};

export default PreciarioTable;