import React from "react";
import PropTypes from "prop-types";

// Assuming ConceptosRow is a separate component
// Import it here if it's in a different file
import ConceptosRow from "./ConceptosRow";

const ConceptosTable = ({ conceptos }) => {
  if (!Array.isArray(conceptos)) {
    return <p>Error: Prop `conceptos` should be an array.</p>;
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr className="bg-light border p-2">
            <th>Nombre</th>
            <th>Unidad</th>
            <th>Cantidad</th>
            <th>Precio</th>
            <th>Importe</th>
          </tr>
        </thead>
        <tbody>
          {conceptos.map((concepto, index) => (
            <ConceptosRow key={index} concepto={concepto} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

ConceptosTable.propTypes = {
  conceptos: PropTypes.array.isRequired,
};

export default ConceptosTable;
