import React, { useState } from "react";
import usePreciario from "../../hooks/usePreciario";
import { formatMonto } from "../../utils";
import EstimacionesConcepto from "../estimaciones/EstimacionesConcepto";
import PillTabs from "../common/PillTabs";
import ConceptoInfo from "./ConceptoInfo";

const ConceptoDetail = () => {
  const [view, setView] = useState("detalles");
  const { concepto } = usePreciario();

  return (
    <div className="container-fluid px-0">
      <p>{concepto.nombre}</p>
      <PillTabs
        value={view}
        modifier={setView}
        tabs={[
          {
            value: "detalles",
            label: "Detalles",
          },
          {
            value: "estimaciones",
            label: "Estimaciones",
          },
        ]}
      />
      {view === "detalles" ? <ConceptoInfo /> : <EstimacionesConcepto />}
    </div>
  );
};

export default ConceptoDetail;
