import React from "react";
import moment from "moment";
import { formatMonto } from "../../utils";
import { createOutline } from "ionicons/icons";
import { IonCol, IonGrid, IonIcon, IonItem, IonRow } from "@ionic/react";
import useProyecto from "../../hooks/useProyecto";

const PagoCard = ({ pago, handleEdit }) => {
  const { permiso } = useProyecto();

  const renderStatus = () => {
    if (pago.fecha_cancelacion !== null) {
      return <span className="badge badge-pill bg-danger">Cancelado</span>;
    }
    if (pago.fecha_hora_autorizacion !== null) {
      return <span className="badge badge-pill bg-success">Autorizado</span>;
    }
    return <span className="badge badge-pill bg-warning">Pendiente</span>;
  };

  const renderFecha = () => {
    if (pago.fecha_cancelacion !== null) {
      return moment(pago.fecha_cancelacion).format("DD MMM YYYY");
    }
    if (pago.fecha_hora_autorizacion !== null) {
      return moment(pago.fecha_hora_autorizacion).format("DD MMM YYYY");
    }
  };

  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <h3>${formatMonto(pago.monto)} MXN</h3>
        </IonRow>
        <IonRow>
          <IonCol size="6" className="ps-0">
            {renderStatus()}
          </IonCol>
          <IonCol size="6" className="pe-0">
            {renderFecha()}
          </IonCol>
        </IonRow>
      </IonGrid>
      {["admin", "finanzas"].includes(permiso) && (
        <IonIcon
          onClick={() => handleEdit(pago)}
          icon={createOutline}
          color="primary"
          slot="end"
        />
      )}
    </IonItem>
  );
};
export default PagoCard;
