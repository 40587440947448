import React, { useEffect, useState, useContext } from "react";
import { RevisionesContext } from "../../context/RevisionesContext";
import { ModalContext } from "../../context/ModalContext";
import { ItemsContext } from "../../context/ItemsContext";
import useProyecto from "../../hooks/useProyecto";
import ItemEstadoForm from "./ItemEstadoForm";
import DateRange from "../global/DateRange";
import UserItem from "../users/UserItem";
import CheckBox from "../obra/CheckBox";
import "../../css/ItemRow.css";
import moment from "moment";
import { CapacitorContext } from "../../context/CapacitorContext";

const ItemRow = ({
  item,
  permiso,
  handleEdit,
  completeItem,
  handleUpload,
  handleCallback,
  handleComments,
}) => {
  const [showName, setShowName] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [itemIsOnRev, setItemIsOnRev] = useState();
  const [completado, setCompletado] = useState(false);

  const { setItem, deleteItem, itemsByRevision } = useContext(ItemsContext);
  const { platform} = useContext(CapacitorContext);
  const { modalComponent, confirm } = useContext(ModalContext);
  const { revision } = useContext(RevisionesContext);
  const { proyecto } = useProyecto();

  const { idItem, nombre } = item;

  useEffect(() => {
    setShowName(false);
  }, []);

  useEffect(() => {
    // console.log(revision);
    if (revision) {
      validateItemOnRev();
    } else {
      setCurrentItem(item);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision, itemsByRevision]);

  const validateItemOnRev = () => {
    const itemRev = itemsByRevision.find((obj) => {
      return obj.idItem === item?.idItem;
    });

    if (itemRev) {
      setCurrentItem(itemRev);
      setItemIsOnRev(true);
      itemRev.completedAt ? setCompletado(true) : setCompletado(false);
    } else {
      setCurrentItem(item);
      setItemIsOnRev(false);
    }
  };

  const handleDeleteItem = () => {
    confirm(
      `¿Estás seguro que deseas eliminar el item ${item.nombre}? Esta acción no puede deshacerse.`,
      () => deleteItem(proyecto.idProyecto, item.idItem, handleCallback)
    );
  };

  const handleDisplayName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowName(!showName);
  };

  const handleStatusInfo = () => {
    setItem(currentItem);
    modalComponent("Estado", <ItemEstadoForm />);
  };

  const renderAdjunto = () => {
    if (revision) {
      return (
        <button
          className="btn btn-sm me-2 btn-outline-primary"
          onClick={() => handleUpload(item)}
        >
          <i className="fa fa-image"></i>
        </button>
      );
    }
  };

  const renderUsuario = () => {
    if (item.responsable !== null) {
      return (
        <div className="px-2">
          <UserItem user={item.responsable} />
        </div>
      );
    }
  };

  const renderComentarios = () => {
    if (revision) {
      return (
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            handleComments(item, idItem);
          }}
        >
          <i className="fa fa-comment"></i>
        </button>
      );
    }
  };

  const renderFechaLimite = () => {
    const fecha_inicio = moment(item.fecha_inicio);
    const fecha_limite = moment(item.fecha_fin);
    let completedOnTime = true;

    if (itemIsOnRev) {
      completedOnTime = fecha_limite > currentItem.completedAt;
    }

    if (fecha_limite.isValid() && fecha_inicio.isValid()) {
      return (
        <DateRange
          end_date={item.fecha_fin}
          start_date={item.fecha_inicio}
          onTime={completedOnTime}
        />
      );
    }
  };

  const renderEdit = () => {
    if (["admin", "coordinador"].includes(permiso)) {
      return (
        <button
          onClick={() => handleEdit(item)}
          className={`btn btn-sm btn-outline-primary me-2 
          ${revision ? "d-none" : null}`}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderNameButton = () => {
    if (String(nombre).length > 80) {
      return (
        <button
          className="btn btn-sm btn-link px-0 text-muted mb-3 show-tablet"
          onClick={handleDisplayName}
        >
          {showName ? "Ocultar" : "Ver completo"}
        </button>
      );
    }
  };

  const renderDelete = () => {
    if (["admin", "coordinador"].includes(permiso)) {
      return (
        <button
          onClick={handleDeleteItem}
          className={`btn btn-sm ms-2 btn-outline-danger
          ${revision ? "d-none" : null}`}
        >
          <i className="fa fa-trash"></i>
        </button>
      );
    }
  };

  const renderItemEstatus = () => {
    if (currentItem?.estado === null) {
      return "Sin revisar";
    }
    if (currentItem?.estado < 7) {
      return (
        <>
          <span className="hide-mobile">Requiere Mantenimiento: </span>
          <span>{currentItem?.estado}</span>
        </>
      );
    } else {
      return (
        <>
          <span className="hide-mobile">Buen Estado: </span>
          <span>{currentItem?.estado}</span>
        </>
      );
    }
  };

  const renderEstatus = () => {
    if (["admin", "coordinador"].includes(permiso) && revision !== null) {
      return (
        <button
          onClick={handleStatusInfo}
          className={`btn btn-sm btn-${
            currentItem?.estado === null
              ? "outline-primary"
              : currentItem?.estado < 7
              ? "warning"
              : "success"
          }`}
        >
          <i
            className={`fa fa-${
              currentItem?.estado > 6 ? "check" : "info"
            }-circle me-2`}
          ></i>{" "}
          {renderItemEstatus()}
        </button>
      );
    }
    return <span>{renderItemEstatus()}</span>;
  };

  return (
    <div 
      className={`
        row align-items-center hover-light border-bottom py-2
        ${platform !== 'web' ? '' : ''}
      `}
    >
      <div 
        className={`
          col-1 ${platform === 'web' ? 'ps-0' : ''}
        `}
      >
        <CheckBox
          checked={completado}
          modifier={() => {
            completeItem(idItem, completado);
            setItem(item);
          }}
        />
      </div>
      <div className="col-11 px-0">
        <div className="row align-items-center">
          <div className={`col-12 col-md-6 ${platform !== 'web' && ('pe-0 mb-3')}`}>
            <p className={`mb-0 ${showName ? "" : "item-overflow-ellipsis"}`}>
              {nombre}
            </p>
            {renderNameButton()}
            {renderFechaLimite()}
            {renderUsuario()}
          </div>
          <div className="col-12 col-md-6 text-end ps-0">
            <div className="row align-items-center">
              <div className="col-8 text-center">
                {renderEstatus()}
                {itemIsOnRev && currentItem.updatedAt !== null && (
                  <span
                    className="d-block my-2"
                    style={{ fontSize: 12 }}
                  >
                    <b>Ultima Actualización:</b>
                    <div>
                      {moment(currentItem?.updatedAt).format("DD MMM YYYY")}
                      <i className="fa fa-calendar-alt text-primary ms-2"></i>
                    </div>
                  </span>
                )}
              </div>
              <div className="col-4 pe-0 text-end">
                {renderEdit()}
                {renderAdjunto()}
                {renderComentarios()}
                {renderDelete()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemRow;
