import React, { useContext, useEffect, useState } from "react";
import ListaClientes from "../components/clientes/ListaClientes";
import ClienteForm from "../components/clientes/ClienteForm";
import { ClientesContext } from "../context/ClientesContext";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import TabOptions from "../components/common/TabOptions";
import { CapacitorContext } from "../context/CapacitorContext";
import { isCoordinador } from "../utils/permisos";
import { AuthContext } from "../context/AuthContext";

const Clientes = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { selectTabs, setButtons } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);

  const [isTableView, setIsTableView] = useState(platform === "web");
  const [query, setQuery] = useState("");

  const { user } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);

  const {
    clientes,
    getClientes,
    editCliente,
    clearCliente,
    createCliente,
    deleteCliente,
  } = useContext(ClientesContext);

  useEffect(() => {
    selectTabs();
    getClientes();
    editCliente(null);
    setSelected({ title: "clientes" });

    if (isCoordinador(user) && platform !== "web") {
      setButtons(
        <button className="btn btn-primary" onClick={handleCreateCliente}>
          <i className="fa fa-plus"></i>
        </button>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    clearCliente();
  };

  const handleEditCliente = (cliente) => {
    editCliente(cliente);

    modalComponent(
      "Editar CLiente",
      <ClienteForm handleCancel={handleCancel} />
    );
  };

  const handleCreateCliente = () => {
    createCliente();

    modalComponent(
      "Crear Cliente",
      <ClienteForm handleCancel={handleCancel} />
    );
  };

  const handleDelete = (cliente) => {
    clearModal();

    modalComponent(
      'Precaución',
      <div className="container-fluid px-0">
        <p>
          {`¿Estás seguro que deseas eliminar el cliente ${cliente.nombre}? 
          Esta acción NO puede deshacerse.`}
        </p>

        <div className="row">
  
          <div className="col-6 text-end ps-0">
            <button className="btn w-100 btn-link text-muted" onClick={clearModal}>
              Cancelar
            </button>
          </div>

          <div className="col-6 pe-0">
            <button
              className="btn btn-danger w-100"
              onClick={() => {
                deleteCliente(cliente.idCliente, () => {
                  clearModal();
                })
              }}
            >
              Eliminar
            </button>
          </div>

        </div>
      </div>
    );
  };

  const renderClientes = () => {
    let currentClients = clientes;

    if (query && query !== "") {
      let currentQuery = String(query).toLowerCase();
      currentClients = clientes.filter((client) => {
        let nombre = String(client.nombre).toLowerCase();
        return nombre.includes(currentQuery);
      });
    }
    return (
      <ListaClientes
        query={query}
        platform={platform}
        clientes={currentClients}
        isTableView={isTableView}
        editCliente={handleEditCliente}
        deleteCliente={handleDelete}
      />
    );
  };

  const renderAddBtn = () => {
    if (isCoordinador(user));
    return (
      <div className="col-12 px-0 col-md-6 text-end">
        <button className="btn btn-primary" onClick={handleCreateCliente}>
          <i className="fa fa-plus"></i>
        </button>
      </div>
    );
  };

  if (platform === "web") {
    return (
      <div className="container-fluid view-container ">
        <TabOptions
          query={query}
          setQuery={setQuery}
          isTableView={isTableView}
          setIsTableView={setIsTableView}
          handleCreate={handleCreateCliente}
          buttonCreateText={"+ NUEVO CLIENTE"}
          searchInputPlaceholder={"Buscar cliente..."}
        />

        {renderClientes()}
      </div>
    );
  }
  return (
    <div className="container-fluid view-container px-0 ">
      <div className="row mt-3">
        <div className="col-12" style={{ height: "max-content" }}>
          <input
            type="text"
            value={query}
            className="form-control bg-white"
            placeholder="Buscar cliente..."
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>

      {renderClientes()}
    </div>
  );
};

export default Clientes;
