import React from "react";
import { formatMonto } from "../../utils";
import usePreciario from "../../hooks/usePreciario";

const ConceptoInfo = () => {
  const { concepto } = usePreciario();

  return (
    <div>
      <h3 className="h5">Detalles</h3>
      <div className="mb-3">
        <div className="row align-items-center border">
          <div className="col bold bg-light py-2">Clave</div>
          <div className="col">{concepto.clave}</div>
        </div>
        <div className="row align-items-center border">
          <div className="col bold bg-light py-2">Unidad</div>
          <div className="col">{concepto.unidad}</div>
        </div>
        <div className="row align-items-center border">
          <div className="col bold bg-light py-2">Cantidad</div>
          <div className="col">{formatMonto(concepto.cantidad)}</div>
        </div>
        <div className="row align-items-center border">
          <div className="col bold bg-light py-2">Precio Unitario</div>
          <div className="col">${formatMonto(concepto.precio_unitario)}</div>
        </div>
        <div className="row align-items-center border">
          <div className="col bold bg-light py-2">Importe</div>
          <div className="col">${formatMonto(concepto.importe)}</div>
        </div>
      </div>
    </div>
  );
};

export default ConceptoInfo;
