import React, { useContext } from "react";
import { formatMonto, getValue } from "../../utils";
import { ConceptosContext } from "../../context/ConceptosContext";

const ConceptoExtraForm = ({ concepto }) => {
  const { deleteConceptoExtra, setPropiedadConceptoExtra } =
    useContext(ConceptosContext);
  const { idConcepto, cantidad, precio_solicitado } =
    concepto;

  return (
    <tr>
      <td>Extra</td>
      <td>
        <input
          type="text"
          value={getValue(concepto, "clave")}
          maxLength={140}
          className="form-control"
          onChange={(e) =>
            setPropiedadConceptoExtra(idConcepto, "clave", e.target.value)
          }
        />
      </td>
      <td>
        <input
          type="text"
          value={getValue(concepto, "nombre")}
          maxLength={140}
          className="form-control"
          onChange={(e) =>
            setPropiedadConceptoExtra(idConcepto, "nombre", e.target.value)
          }
        />
      </td>
      <td>
        <input
          type="text"
          value={getValue(concepto, "unidad")}
          maxLength={140}
          className="form-control"
          onChange={(e) =>
            setPropiedadConceptoExtra(idConcepto, "unidad", e.target.value)
          }
        />
      </td>
      <td>
        <input
          min={0}
          type="number"
          value={getValue(concepto, "cantidad")}
          className="form-control"
          onChange={(e) =>
            setPropiedadConceptoExtra(idConcepto, "cantidad", e.target.value)
          }
        />
      </td>
      <td>
        <input
          min={0}
          type="number"
          value={getValue(concepto, "precio_solicitado")}
          className="form-control"
          onChange={(e) =>
            setPropiedadConceptoExtra(
              idConcepto,
              "precio_solicitado",
              e.target.value
            )
          }
        />
      </td>
      <td>-</td>
      <td>
        {"$"}
        {formatMonto(cantidad * precio_solicitado)}
      </td>
      <td>
        <button
          className="btn btn-outline-danger"
          onClick={() => deleteConceptoExtra(idConcepto)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
};

export default ConceptoExtraForm;
