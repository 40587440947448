import React from "react";
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { formatMonto } from "../../utils";
import moment from "moment";

const EstimacionesConcepto = () => {
  const concepto = {
    anterior: 0,
    estimado: 12456,
    supervisado: 11890,
    autorizado: 11890,
    importe: 267168.3,
  };

  const estimaciones = [
    {
      idEstimacion: 1,
      numero: 1,
      fecha: "2024-05-01",
    },
    {
      idEstimacion: 2,
      numero: 2,
      fecha: "2024-05-07",
    },
    {
      idEstimacion: 3,
      numero: 3,
      fecha: "2024-05-15",
    },
  ];

  const renderEstimaciones = () => {
    if (Array.isArray(estimaciones)) {
      return estimaciones.map((estimacion) => (
        <IonAccordion key={estimacion.numero} value={estimacion.idEstimacion}>
          <IonItem slot="header" color="light">
            <IonLabel>
              Estimación #{estimacion.numero} -{" "}
              {moment(estimacion.fecha).format("DD MMM YYYY")}
            </IonLabel>
          </IonItem>
          <div slot="content" className="py-2">
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Anterior</div>
              <div className="col">{formatMonto(concepto.anterior)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Estimado</div>
              <div className="col">{formatMonto(concepto.estimado)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Supervisado</div>
              <div className="col">{formatMonto(concepto.supervisado)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Autorizado</div>
              <div className="col">{formatMonto(concepto.autorizado)}</div>
            </div>
            <div className="row align-items-center border">
              <div className="col bold bg-light py-2">Importe</div>
              <div className="col">${formatMonto(concepto.importe)}</div>
            </div>
          </div>
        </IonAccordion>
      ));
    }
  };

  return (
    <div>
      <h3 className="h5">Estimaciones</h3>
      <IonAccordionGroup>{renderEstimaciones()}</IonAccordionGroup>
    </div>
  );
};

export default EstimacionesConcepto;
