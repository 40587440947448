import React, { useContext, useEffect } from "react";
import PillTabs from "../common/PillTabs";
import PreciarioTable from "./PreciarioTable";
import usePreciario from "../../hooks/usePreciario";
import PreciarioMobile from "../preciario/PreciarioMobile";
import PreciarioButtons from "../preciario/PreciarioButtons"
import { ConceptosContext } from "../../context/ConceptosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { PreciarioContext } from "../../context/PreciarioContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import EstimacionesButtons from "../estimaciones/EstimacionesButtons";
import EstimacionesContrato from "../estimaciones/EstimacionesContrato";
import { PartidasContratoContext } from "../../context/PartidasContratoContext";

const Preciario = ({ idContrato }) => {
  const { getConceptosContrato } = useContext(ConceptosContext);
  const { getPartidasContrato } = useContext(PartidasContratoContext);
  const { PartidaContratoName } = useContext(AppConfigContext);
  const { platform } = useContext(CapacitorContext);
  const { proyecto } = useContext(ProyectosContext);
  const { editMode } = useContext(PreciarioContext);
  const { idProyecto, permiso } = proyecto;

  const { view, query, setView, setQuery, partidas } = usePreciario();

  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    getPartidasContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  function renderHeader() {
    if (platform === "web")
      return (
        <div className="row align-items-center">
          <div className="col-12 col-md-8 px-0 mb-2">
            <h2 className="h4 mb-0 bold">Catálogo</h2>
            <input
              type="text"
              value={query}
              className="form-control bg-light"
              placeholder="Buscar partidas y conceptos"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="col-12 col-md-4 px-0 mb-2">
            <EstimacionesContrato
              idProyecto={idProyecto}
              idContrato={idContrato}
              permiso={permiso}
            />
          </div>
        </div>
      );
    return (
      <PillTabs
        value={view}
        modifier={setView}
        tabs={[
          {
            label: "Catálogo",
            value: "catalogo",
          },
          {
            label: "Estimaciones",
            value: "estimaciones",
          },
        ]}
      />
    );
  }

  function renderBotones() {
    if (!["cliente", "invitado"].includes(permiso) && platform === "web") {
      return (
        <div className="row mt-3">
          <div className="container-fluid px-0">
            <PreciarioButtons />
            <EstimacionesButtons />
          </div>
        </div>
      );
    }
  }

  function renderPreciario() {
    if (Array.isArray(partidas)) {
      if (editMode !== "partidas") {
        if (partidas.length === 0) {
          return (
            <p>No hay {PartidaContratoName} registradas para este contrato.</p>
          );
        }
      }
      if (platform === "web") return <PreciarioTable />;
      return <PreciarioMobile />;
    }
  }

  function renderSelectEstimacion() {
    if (platform !== "web" && view === "estimaciones") {
      return (
        <EstimacionesContrato
          idProyecto={idProyecto}
          idContrato={idContrato}
          permiso={permiso}
        />
      );
    }
  }

  return (
    <div className="card p-3 shadow my-3">
      {renderHeader()}
      {renderSelectEstimacion()}
      {renderPreciario()}
      {renderBotones()}
    </div>
  );
};

export default Preciario;
