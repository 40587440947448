import React, { useContext } from "react";
import { ChecklistContext } from "../../context/ChecklistContext";
import { modes } from "../../utils/checklists";

const ChecklistModes = () => {
  const { mode, setMode } = useContext(ChecklistContext);

  const renderTabs = () => {
    return modes.map((tab, index) => {
      const active = tab.key === mode;
      const firstTab = index === 0;
      return (
        <li
          key={tab.key}
          style={{ fontSize: "18px" }}
          className={`nav-item bold ${firstTab ? "ps-0" : ""} ps-0`}
          onClick={() => setMode(tab.key)}
        >
          <p
            role="button"
            className={`nav-link mb-0 ${
              active ? "bg-primary text-white" : "text-dark"
            } `}
          >
            {tab.label}
          </p>
        </li>
      );
    });
  };

  return (
    <div className="row px-2">
      <div className="card px-3 py-2 my-3">
        <div className="row mx-auto w-100">
          <div className="col-12 ps-0">
            <ul className="nav nav-pills">{renderTabs()}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistModes