import React, { useContext, useEffect } from "react";
import { ModalContext } from "../context/ModalContext";
import ChartCard from "../components/analiticas/ChartCard";
import PricesTable from "../components/prices/PricesTable";
import { ConceptosContext } from "../context/ConceptosContext";
import ConceptoForm from "../components/conceptos/ConceptoForm";
import CategoriaConceptos from "../components/conceptos/CategoriaConceptos";
import ConceptoPriceChart from "../components/conceptos/ConceptoPriceChart";
import ConceptoProfitChart from "../components/conceptos/ConceptoProfitChart";
import ConceptoEstimadoChart from "../components/conceptos/ConceptoEstimadoChart";
import { useParams } from "react-router-dom";

const SingleConcepto = ({ idProyecto, idContrato }) => {
  const { modalComponent } = useContext(ModalContext);
  const { concepto, conceptos, getConceptoPrecios, getSingleConcepto, getConceptosContrato } =
    useContext(ConceptosContext);

  const { idConcepto } = useParams();

  useEffect(() => {
    getConceptoPrecios(idConcepto, idProyecto);
    getSingleConcepto(idProyecto, idConcepto);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getConceptosContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  const handleEdit = () => {
    modalComponent("Editar Concepto", <ConceptoForm />);
  };

  return (
    <div className="container-fluid h-100 overflow-y-auto">
      <div className="row my-2">
        <div className="col-12 col-md-6">
          <h1 className="h2 bold">Concepto {concepto?.clave}</h1>
        </div>
        <div className="col-12 col-md-6 text-end">
          <button onClick={handleEdit} className="btn btn-primary mb-2">
            <i className="fa fa-edit me-1"></i>{" "}
            <span className="hide-mobile">Editar </span>
          </button>
        </div>
      </div>
      <p className="px-3">{concepto?.concepto}</p>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card p-3 shadow mb-3">
            <div className="row mb-3">
              <div className="col-6 ps-0">
                <h4>Precios</h4>
              </div>
              <div className="col-6 text-end pe-0">
                <button className="btn btn-primary">
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <PricesTable prices={concepto?.all_precios} />
          </div>

          <CategoriaConceptos
            items={conceptos}
            titulo="Sub Conceptos"
            conceptos={concepto?.subconceptos}
          />

          <CategoriaConceptos
            items={conceptos}
            titulo="Herramientas"
            conceptos={concepto?.subconceptos}
          />

          <CategoriaConceptos
            items={conceptos}
            titulo="Materiales"
            conceptos={concepto?.subconceptos}
          />

          <CategoriaConceptos
            items={conceptos}
            titulo="Mano de Obra"
            conceptos={concepto?.subconceptos}
          />
        </div>

        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12 col-md-6">
              <ChartCard titulo="Margen de Ganancia">
                <ConceptoProfitChart concepto={concepto} />
              </ChartCard>
            </div>
            <div className="col-12 col-md-6">
              <ChartCard titulo="Costo vs Precio">
                <ConceptoPriceChart prices={concepto?.all_precios} />
              </ChartCard>
            </div>
          </div>
          <div className="row">
            <div className="container-fluid">
              <ChartCard titulo="Estimaciones">
                <ConceptoEstimadoChart
                  total={concepto?.cantidad}
                  estimaciones={concepto?.estimados}
                />
              </ChartCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleConcepto;
