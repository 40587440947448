import React, { useContext, useState } from "react";
import { PartidasContratoContext } from "../context/PartidasContratoContext";
import { EstimacionesContext } from "../context/EstimacionesContext";
import ConceptoDetail from "../components/conceptos/ConceptoDetail";
import { ConceptosContext } from "../context/ConceptosContext";
import { PreciarioContext } from "../context/PreciarioContext";
import { ModalContext } from "../context/ModalContext";
import useProyecto from "./useProyecto";


const usePreciario = () => {
  const [query, setQuery] = useState("");
  const [view, setView] = useState("catalogo");
  const [showConceptos, setShowConceptos] = useState([]);

  const { concepto, conceptos, conceptosExtra , setConcepto } = useContext(ConceptosContext);
  const { partidas } = useContext(PartidasContratoContext);
  const { estimacion } = useContext(EstimacionesContext);
  const { modalComponent } = useContext(ModalContext);
  const { editMode } = useContext(PreciarioContext);
  const { permiso } = useProyecto();

  function showConceptoDetail(concepto) {
    setConcepto(concepto);
    modalComponent(`Concepto ${concepto.clave}`, <ConceptoDetail />);
  }

  function toggleConceptosPartida(idPartidaContrato) {
    const current = [...showConceptos];
    const index = showConceptos.indexOf(idPartidaContrato);
    if(index === -1) current.push(idPartidaContrato);
    else current.splice(index, 1);
    setShowConceptos(current);
  }

  let partidasRender = Array.isArray(partidas) ? [...partidas] : [];
  let conceptosRender = Array.isArray(conceptos) ? [...conceptos] : [];
  
  if (editMode === "partidas") {
    partidasRender = partidas.filter(
      (partida) => String(partida.idPartidaContrato)[0] !== "d"
    );
  }

  if (editMode === "conceptos-extra") {
    conceptosRender = conceptosExtra.filter(
      (concepto) => String(concepto.idConceptoExtra)[0] !== "d"
    );
  }

  if (editMode === "conceptos-estimacion") {
    if (["finanzas", "obras"].includes(permiso)) {
      let conceptosEstimados = conceptos.filter(
        (concepto) => concepto.estimado
      );
      let partidasConceptosEstimados = conceptosEstimados.map(
        (concepto) => concepto.idPartidaContrato
      );
      partidasRender = partidasRender.filter((partida) =>
        partidasConceptosEstimados.includes(partida.idPartidaContrato)
      );
    }
  }

  if (estimacion !== null) {
    partidasRender = partidasRender.filter((partida) => {
      return (
        conceptos.find(
          (concepto) => concepto.idPartidaContrato === partida.idPartidaContrato
        ) !== undefined
      );
    });
  }

  function getConceptosPartida(idPartidaContrato) {
    return conceptosRender.filter(concepto => concepto.idPartidaContrato === idPartidaContrato);
  }

  return {
    view,
    query,
    setView,
    setQuery,
    concepto,
    showConceptos,
    conceptosExtra,
    showConceptoDetail,
    getConceptosPartida,
    toggleConceptosPartida,
    partidas: partidasRender,
    conceptos: conceptosRender,
  };
};

export default usePreciario;
