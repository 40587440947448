import React, { useContext, useEffect } from "react";
import Home from "./Home";
import Login from "./Login";
import Recuperar from "./views/Recuperar";
import { AuthContext } from "./context/AuthContext";
import { MenuContext } from "./context/MenuContext";
import { ProyectosContext } from "./context/ProyectosContext";
import { CapacitorContext } from "./context/CapacitorContext";
import Modal from "./components/global/Modal";
import ErrorAlert from "./components/global/ErrorAlert";
import SuccessAlert from "./components/global/SuccessAlert";
import { Routes, Route, useNavigate } from "react-router-dom";
import { AppConfigContext } from "./context/AppConfigContext";
import AdjuntoView from "./components/folder/AdjuntoView";
import MobileModal from "./components/folder/MobileModal";
import { applyAppConfig } from "./utils/appconfig";
import Terms from "./views/pages/Terms";

const Main = () => {
  const navigate = useNavigate();
  const { hideOptions } = useContext(ProyectosContext);
  const { user, logout, userLoggedIn } = useContext(AuthContext);
  const { showSettings, toggleSettings } = useContext(MenuContext);
  const appconfig = useContext(AppConfigContext);
  const {
    platform,
    fcm_token,
    setupDevice,
    setupPlatform,
    registerNotifications,
  } = useContext(CapacitorContext);

  useEffect(() => {
    setupDevice();
    setupPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyAppConfig(appconfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appconfig]);

  useEffect(() => {
    if (platform !== null && platform !== "web") {
      registerNotifications(platform);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform]);

  useEffect(() => {
    if (platform === "web" && platform !== null) {
      if (user === null) {
        userLoggedIn();
      }
      window.onclick = hideModals;
    }

    if (platform !== "web" && platform !== null) {
      // if(user === null && fcm_token !== null) {
      userLoggedIn();
      // }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcm_token, platform]);

  useEffect(() => {
    if (logout) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  const hideModals = (event) => {
    const { target } = event;
    if (!Array.from(target.classList).includes("custom-modal")) {
      hideOptions();
      if (showSettings) {
        toggleSettings();
      }
    }
  };

  const renderContent = () => {
    if (user !== null) {
      return (
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/privacidad" element={<Terms />} />{" "}
        </Routes>
      );
    }
    return (
      <Routes>
        <Route path="/*" element={<Login />} />
        <Route path="/privacidad" element={<Terms />} />
        <Route path="/recuperar" element={<Recuperar />} />
      </Routes>
    );
  };

  return (
    <div className={platform !== "web" ? "ionic" : ""}>
      {renderContent()}
      {platform === "web" ? <Modal /> : <MobileModal />}
      <ErrorAlert />
      <SuccessAlert />
      <AdjuntoView />
    </div>
  );
};

export default Main;
