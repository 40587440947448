import React, { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../context/MenuContext";
import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import "../../css/navbarBottom.css";

const SingleTab = ({ title, selected, name, icon, link }) => {
  return (
    <div className="flex-grow-1 text-center">
      <Link
        to={link}
        className={`d-flex flex-column w-100 tab px-3 py-4 br-0 ${
          selected === name ? "tab-selected" : ""
        }`}
      >
        <i
          className={`fas fa-${icon} ${
            typeof selected === "object"
              ? String(selected.title).toLowerCase() === name.toLowerCase()
                ? "text-primary"
                : ""
              : String(selected).toLowerCase() === name.toLowerCase()
              ? "text-primary"
              : ""
          }`}
        ></i>
        <span className="text-capitalize my-2" style={{ fontSize: "10px" }}>
          {" "}
          {title}{" "}
        </span>
      </Link>
    </div>
  );
};

const BottomNavbar = () => {
  const { tabs, selected } = useContext(MenuContext);
  const { user } = useContext(AuthContext);

  const location = useLocation();

  const showAllTabs = false;
  const [showHiddenTabs, setShowHiddenTabs] = useState(false);

  useEffect(() => {
    setShowHiddenTabs(false);
  }, [location]);

  const HiddenTabs = ({ tabs }) => {
    return (
      <div
        className={`hidden-tabs position-fixed d-flex flex-column justify-content-start align-items-center ${
          showHiddenTabs ? "fadeInUp" : ""
        }`}
      >
        {tabs.map((tab) => (
          <SingleTab key={tab.name} {...tab} selected={selected} />
        ))}
      </div>
    );
  };

  const renderTabs = () => {
    let tabsRender = tabs.filter(
      (tab) => tab.permiso === user.permiso || !tab.permiso
    );

    const visibleTabs = showAllTabs ? tabsRender : tabsRender.slice(0, 4);
    const hiddenTabs = showAllTabs ? [] : tabsRender.slice(4);

    return (
      <div className="d-flex">
        {visibleTabs.map((tab) => (
          <SingleTab key={tab.name} {...tab} selected={selected} />
        ))}
        {hiddenTabs.length > 0 && (
          <div
            className="flex-grow-1 text-center"
            onClick={() => setShowHiddenTabs(!showHiddenTabs)}
            style={{ zIndex: "999999" }}
          >
            <button
              className="btn w-100 tab py-4 px-3 br-0"
              onClick={() => setShowHiddenTabs(!showHiddenTabs)}
            >
              <i
                onClick={() => setShowHiddenTabs(!showHiddenTabs)}
                className="fas fa-ellipsis-v"
                style={{ color: "#db8c61" }}
              ></i>
              <span
                className="text-capitalize my-2 d-block"
                style={{ fontSize: "10px" }}
              >
                Más
              </span>
            </button>
          </div>
        )}
        {showHiddenTabs && (
          <>
            <div className="overlay" onClick={() => setShowHiddenTabs(false)} />
            <HiddenTabs tabs={hiddenTabs} />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="bottom-navbar border-top bg-white shadow"
        style={{ 
          zIndex: "-1",
          height: "75px"
        }}
      >
        <div className="container-fluid px-0">{renderTabs()}</div>
      </div>
      {showHiddenTabs && (
        <div className="overlay" onClick={() => setShowHiddenTabs(false)} />
      )}
    </>
  );
};

export default BottomNavbar;
