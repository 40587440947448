import React, { useContext } from "react";
import { getValue } from "../../utils";
import Responsable from "../cronograma/Responsable";
import { ItemsContext } from "../../context/ItemsContext";

const ItemForm = ({
  idChecklistSection,
  handleCancel,
  idChecklist,
  idProyecto,
  handleChanges,
}) => {
  const { item, postItem, setPropertyItem } = useContext(ItemsContext);

  const onSubmit = (e) => {
    e.preventDefault();
    postItem(idProyecto, { ...item, idChecklist, idChecklistSection });
  };

  return (
    <form onSubmit={onSubmit} className="px-3">
      <label className="h6 text-capitalize">Nombre</label>
      <input
        type="text"
        value={getValue(item, "nombre")}
        className="form-control mb-3"
        onChange={(e) => setPropertyItem("nombre", e.target.value)}
      />
      <div className="row">
        <div className="col-6 ps-0">
          <label className="h6 text-capitalize">Fecha Inicio</label>
          <input
            type="date"
            value={getValue(item, "fecha_inicio", "date")}
            className="form-control mb-3"
            onChange={(e) => setPropertyItem("fecha_inicio", e.target.value)}
          />
        </div>
        <div className="col-6 pe-0">
          <label className="h6 text-capitalize">Fecha Fin</label>
          <input
            type="date"
            value={getValue(item, "fecha_fin", "date")}
            className="form-control mb-3"
            onChange={(e) => setPropertyItem("fecha_fin", e.target.value)}
          />
        </div>
      </div>
      {item !== null && (
        <Responsable
          item={item}
          modifier={(key, value) => setPropertyItem(key, value)}
        />
      )}
      <div className="row mt-3 align-items-center">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn text-muted w-100"
          >
            CANCELAR
          </button>
        </div>
        <div className="col-6 px-0">
          <button className="btn btn-primary w-100" type="submit">
            GUARDAR
          </button>
        </div>
      </div>
    </form>
  );
};

export default ItemForm;
