import React, { useContext, useEffect, useState } from "react";
import Obra from "./views/Obra";
import Obras from "./views/Obras";
import Alertas from "./views/Alertas";
import Usuarios from "./views/Usuarios";
import Clientes from "./views/Clientes";
import Menu from "./components/menu/Menu";
import { CapacitorContext } from "./context/CapacitorContext";
import BottomNavbar from "./components/global/BottomNavbar";
import MenuMobile from "./components/menu/MenuMobile";
import SingleCliente from "./views/SingleCliente";
import { Routes, Route } from "react-router-dom";
import Permisos from "./views/Permisos";
import Navbar from "./views/Navbar";
import Ajustes from "./views/Ajustes";
import { AppConfigContext } from "./context/AppConfigContext";
import { getValue } from "./utils";
import Almacen from "./views/Almacen";
import SingleProduct from "./views/SingleProduct";
import PurchaseOrder from "./views/PurchaseOrder";
import Proveedores from "./views/Proveedores";
import SingleProveedor from "./views/SingleProveedor";
import MobileSubHeader from "./components/movil/MobileSubHeader";
import usePushNotifications from "./hooks/usePushNotifications";
import { MenuContext } from "./context/MenuContext";
import { hasNotch } from "./utils/ionic";

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const appconfig = useContext(AppConfigContext);
  const { platform, device } = useContext(CapacitorContext);
  const { buttons } = useContext(MenuContext);

  usePushNotifications();

  const renderBottomNavbar = () => {
    if (platform !== "web") {
      return <BottomNavbar />;
    }
  };

  const renderAjustes = () => {
    if (getValue(appconfig, "show_settings", "boolean")) {
      return <Route path="/ajustes" element={<Ajustes />} />;
    }
  };

  const renderMobileSubHeader = () => {
    if(platform !== "web") {
      return <MobileSubHeader />
    }
  }

  const isBitacora = window.location.pathname.includes('bitacora');

  const getContainerView = () => {
    if(platform === 'web') {
      return '100%';
    } else if(buttons === null){
      return 'calc(100% - 35.2px)';
    } else {
      return 'calc(100% - 50px)';
    }
  }

  const getContentPaddingTop = () => {
    if(platform !== 'web') {
      if(hasNotch(device)) {
        return {
          paddingTop: 109
        };
      } 
    }
  }

  return (
    <div 
      className={`
        container-fluid vw-100 px-0 vh-100 
        ${platform !== 'web' ? 'tablet-device' : ''}
      `}
    >
      <Menu setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
      <Navbar menuOpen={menuOpen} />
      <div 
        id="content" 
        className={`bg-light h-100`}
        style={{
          paddingBottom: `${platform !== 'web' && '75px'}`,
          ...getContentPaddingTop()
        }}
      >
        <MenuMobile />
        {renderMobileSubHeader()}
        <div 
          className={`
            ${platform === 'web' ? 'px-2' : ''} 
            ${platform === 'web' && !isBitacora ? 'py-3' : ''}
            ${isBitacora ? 'pb-2' : ''}`
          }
          style={{
            height: getContainerView()
          }}
        >
          <Routes>
            {renderAjustes()}
            <Route path="/" element={<Obras />} />
            <Route path="/alertas" element={<Alertas />} />
            <Route path="/usuarios" element={<Usuarios />} />
            <Route path="/clientes" element={<Clientes />} />
            <Route path="/permisos" element={<Permisos />} />
            <Route path="/almacen" element={<Almacen />} />
            <Route path="/proveedores" element={<Proveedores />} />
            <Route
              path="/proveedores/:idProveedor"
              element={<SingleProveedor />}
            />
            <Route path="/orden/:idOrden" element={<PurchaseOrder />} />
            <Route
              path="/almacen/producto/:idProducto"
              element={<SingleProduct />}
            />
            <Route path="/clientes/:idCliente" element={<SingleCliente />} />
            <Route path="/obra/:idProyecto/*" element={<Obra />} />
          </Routes>
        </div>
        {renderBottomNavbar()}
      </div>
    </div>
  );
};

export default Home;
