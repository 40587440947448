import React, { useContext, useEffect } from "react";
import RecorridoCard from "../components/recorridos/RecorridoCard";
import RecorridoForm from "../components/recorridos/RecorridoForm";
import { RecorridosContext } from "../context/RecorridosContext";
import { ProyectosContext } from "../context/ProyectosContext";
import { ModalContext } from "../context/ModalContext";
import { MenuContext } from "../context/MenuContext";
import { useNavigate } from "react-router-dom";
import { CapacitorContext } from "../context/CapacitorContext";

const Recorridos = ({ idProyecto }) => {
  const {
    editTour,
    recorrido,
    recorridos,
    deleteTour,
    getRecorridos,
    createRecorrido,
  } = useContext(RecorridosContext);

  const navigate = useNavigate();

  const { setSelected } = useContext(MenuContext);

  const { proyecto, getSingleProyecto } = useContext(ProyectosContext);

  const { confirm, modalComponent } = useContext(ModalContext);
  const { platform } = useContext(CapacitorContext);

  useEffect(() => {
    getSingleProyecto(idProyecto);
    getRecorridos(idProyecto);
    setSelected({ title: "recorridos" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    createRecorrido();
    modalComponent(
      "Agregar Recorrido",
      <RecorridoForm idProyecto={idProyecto} />
    );
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    editTour(recorrido);
    modalComponent(
      "Editar Recorrido",
      <RecorridoForm idProyecto={idProyecto} />
    );
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    confirm(
      `¿Estás seguro que deseas eliminar el recorrido ${recorrido.nombre}? Esto eliminará todos los archivos y fotografías del recorrido. Esta acción NO puede deshacerse.`,
      () => deleteTour(idProyecto, recorrido.idTour)
    );
  };

  const renderRecorridos = () => {
    if (Array.isArray(recorridos) && proyecto !== null) {
      if (recorridos.length === 0) {
        return <p>No hay recorridos para esta Obra</p>;
      }
      return (
        <div className={`container-fluid ${platform === 'web' && 'px-0'}`}>
          {recorridos.map((recorrido) => (
            <div 
              className="col-12"
              key={recorrido.idTour}
            >
              <RecorridoCard
                id={idProyecto}
                permiso={proyecto.permiso}
                recorrido={recorrido}
                editRecorrido={handleEdit}
                deleteRecorrido={handleDelete}
                onClick={() =>
                  navigate(`/obra/${idProyecto}/recorridos/${recorrido.idTour}`)
                }
              />
            </div>
           
          ))}
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderEtiquetas = () => {
    if (Array.isArray(recorridos) && proyecto !== null) {
      if (recorridos.length > 0) {
        return (
          <div className="container-fluid mb-2 hide-mobile">
            <div className="row">
              <div className="col">
                <h5 className="bold">Recorrido:</h5>
              </div>
              <div className="col hide-mobile">
                <h5 className="bold">Subido por:</h5>
              </div>
              <div className="col">
                <h5 className="bold">Fecha:</h5>
              </div>
              <div className="col">
                {["admin", "supervisor"].includes(proyecto.permiso) && (
                  <h5 className="bold">Acciones</h5>
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  const renderButton = () => {
    if (proyecto && proyecto !== null) {
      if (["admin", "supervisor"].includes(proyecto.permiso)) {
        return (
          <div className="container-fluid ps-00 text-end pr-0">
            <button
              className="btn btn-outline-primary me-2"
              onClick={() =>
                window.open("https://www.marzipano.net/tool", "__blank")
              }
            >
              <i className="fa fa-plus "></i> <span className="hide-mobile ms-1">Crear</span> 
            </button>
            <button className="btn btn-outline-primary" onClick={handleCreate}>
              <i className="fa fa-upload "></i> <span className="hide-mobile ms-1">Subir</span>
            </button>
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid h-100 px-0">
      {renderEtiquetas()}
      {renderRecorridos()}
    </div>
  );
};

export default Recorridos;
