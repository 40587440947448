import React, { useContext, useEffect } from "react";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import SelectEstimacion from "../finanzas/SelectEstimacion";
import EstimacionForm from "../finanzas/EstimacionForm";
import { ModalContext } from "../../context/ModalContext";
import { notCliente } from "../../utils/permisos";
import { ProyectosContext } from "../../context/ProyectosContext";

const EstimacionesContrato = ({ idProyecto, idContrato, permiso }) => {
  const { modalComponent } = useContext(ModalContext);
  const { proyecto } = useContext(ProyectosContext);
  const {
    estimaciones,
    createEstimacion,
    selectEstimacion,
    getEstimacionesContrato,
  } = useContext(EstimacionesContext);

  useEffect(() => {
    fetchEstimaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto, idContrato]);

  function fetchEstimaciones() {
    getEstimacionesContrato(idProyecto, idContrato);
  }

  function handleSelect(idEstimacion) {
    selectEstimacion(idProyecto, idContrato, idEstimacion);
  }

  function handleCreateEstimacion() {
    createEstimacion();
    modalComponent(
      "Agregar Estimacion",
      <EstimacionForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCallback={fetchEstimaciones}
      />
    );
  }

  function renderEstimaciones() {
    if (Array.isArray(estimaciones)) {
      return (
        <SelectEstimacion
          permiso={permiso}
          idProyecto={idProyecto}
          idContrato={idContrato}
          estimaciones={estimaciones}
          selectEstimacion={handleSelect}
          createEstimacion={createEstimacion}
        />
      );
    }
  }

  return (
    <div>
      <h3 className="h5 bold mb-0">Estimaciones</h3>
      <div className="row">
        <div className="col-9 px-0">{renderEstimaciones()}</div>
        <div className="col-3 text-end">
          {notCliente(proyecto) && (
            <button
              onClick={handleCreateEstimacion}
              className="btn btn-outline-primary"
            >
              +
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EstimacionesContrato;
