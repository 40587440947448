import React, { useContext } from "react";
import { formatMonto, getValue } from "../../utils";
import Folder from "../../views/Folder";
import { ModalContext } from "../../context/ModalContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import CreatableSelect from 'react-select/creatable';

const ConceptoEstimado = ({
  permiso,
  concepto,
  modifier,
  estimacion,
  idProyecto,
}) => {
  const {
    idConcepto,
    idAdjunto,
    estimado,
    costo_estimado,
    precio_estimado,
    supervisado,
    autorizado,
    all_precios,
    precio_autorizado,
  } = concepto;

  const conceptoPrecios = all_precios
    .map(obj => ({ value: obj.Precio?.precio_unitario, label: `$${obj.Precio?.precio_unitario}` }))
    .filter(precio => precio.value ? precio : null);

  const { modalComponent } = useContext(ModalContext);
  const appconfig = useContext(AppConfigContext);

  function showModal() {
    const { idFolder, numero } = estimacion;
    modalComponent(
      "Elegir Evidencia",
      <Folder
        title={`Estimacion #${numero}`}
        idFolder={idFolder}
        idProyecto={idProyecto}
        hideFolderButton
        onClick={(adjunto) =>
          modifier(concepto.idConcepto, "idAdjunto", adjunto.idAdjunto)
        }
      />
    );
  }

  function renderCol(col) {
    const anterior = `${col}Anterior`;
    const value = parseFloat(concepto[col]) - parseFloat(concepto[anterior]);
    if (value > 0) return value;
    return 0;
  }

  function renderImporte() {
    const autorizadoActual =
      parseFloat(concepto.autorizado) - parseFloat(concepto.autorizadoAnterior);
    if (autorizadoActual > 0) {
      return autorizadoActual * parseFloat(concepto.precio_autorizado);
    }
    return 0;
  }

  return (
    <>
      <td className="bg-secondary ps-3">{concepto.estimadoAnterior}</td>

      <td className="bg-secondary">
        <input
          min={0}
          type="number"
          value={estimado}
          className="form-control"
          onChange={(e) => {
            modifier(idConcepto, "estimado", e.target.value);
            if (!getValue(appconfig, "supervise_estimates", "boolean")) {
              modifier(idConcepto, "supervisado", e.target.value);
            }
            if (!getValue(appconfig, "authorize_estimates", "boolean")) {
              modifier(idConcepto, "autorizado", e.target.value);
            }
          }}
        />
      </td>

      <td className="bg-secondary">
        <CreatableSelect 
          defaultValue={{
            value: precio_estimado,
            label: `$${precio_estimado}`
          }}

          onChange={(newValue) => {
            modifier(idConcepto, "precio_estimado", newValue.value);
          }}
          options={conceptoPrecios}
        />
      </td>


      <td className="bg-secondary">
        <div className="input-group">
          <span className="input-group-text" id="basic-addon1">$</span>
          <input
            min={0}
            type="number"
            value={costo_estimado}
            className="form-control border"
            onChange={(e) => {
              modifier(idConcepto, "costo_estimado", e.target.value);
            }}
          />
        </div>

      </td>

      <td className="bg-secondary">{renderCol("estimado")}</td>

      {getValue(appconfig, "supervise_estimates", "boolean") && [
        <td className="bg-secondary">{concepto.supervisadoAnterior}</td>,
        <td className="bg-secondary">
          <input
            min={0}
            type="number"
            value={supervisado}
            className="form-control"
            onChange={(e) =>
              modifier(idConcepto, "supervisado", e.target.value)
            }
          />
        </td>,
        <td className="bg-secondary">{renderCol("supervisado")}</td>,
      ]}
      {getValue(appconfig, "authorize_estimates", "boolean") && [
        <td className="bg-secondary">{concepto.autorizadoAnterior}</td>,
        <td className="bg-secondary">
          {["admin", "finanzas"].includes(permiso) && supervisado !== null ? (
            <input
              min={0}
              type="number"
              value={autorizado}
              className="form-control"
              onChange={(e) =>
                modifier(idConcepto, "autorizado", e.target.value)
              }
            />
          ) : (
            formatMonto(autorizado)
          )}
        </td>,
        <td className="bg-secondary">{renderCol("autorizado")}</td>,
        <td className="bg-secondary">
          {["admin", "finanzas"].includes(permiso) && supervisado !== null ? (
            <input
              min={0}
              type="number"
              value={precio_autorizado}
              className="form-control"
              onChange={(e) =>
                modifier(idConcepto, "precio_autorizado", e.target.value)
              }
            />
          ) : (
            <>${formatMonto(precio_autorizado)}</>
          )}
        </td>,
      ]}
      <td className="bg-secondary">${formatMonto(renderImporte())}</td>
      <td className="bg-secondary">
        <button className="btn btn-outline-secondary" onClick={showModal}>
          {idAdjunto && idAdjunto !== null ? (
            <i className="fa fa-edit" />
          ) : (
            <i className="fa fa-image" />
          )}
        </button>
      </td>
    </>
  );
};

export default ConceptoEstimado;
