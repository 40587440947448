import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ConceptoPriceChart = ({ prices }) => {
  const [currentPrecios, setCurrentPrecios] = useState([0]);
  const [currentCostos, setCurrentCostos] = useState([0]);
  
  useEffect(() => {
    if(prices) {
      const costos = prices?.map(({ costo_unitario }) => costo_unitario);
      const precios = prices?.map(({ precio_unitario }) => precio_unitario);
      
      setCurrentCostos(costos);
      setCurrentPrecios(precios);
    }
  }, [prices]);

  return (
    <Chart
      type="line"
      options={{
        colors: ["#007991", "#89D405"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          labels: {
            show: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            offset: -1,
          },
        },
      }}
      series={[
        {
          name: "Costo",
          data: currentCostos,
        },
        {
          name: "Precio",
          data: currentPrecios,
        },
      ]}
    />
  );
};

export default ConceptoPriceChart;
