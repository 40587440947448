import React, { useContext } from "react";
import ChecklistSectionForm from "../checklistsection/ChecklistSectionForm";
import { ModalContext } from "../../context/ModalContext";
import ChecklistsRevisions from "./ChecklistRevisions";
import useChecklists from "../../hooks/useChecklists";
import { isCoordinador } from "../../utils/permisos";
import { itemStatus } from "../../utils/checklists";
import useProyecto from "../../hooks/useProyecto";
import DropDown from "../common/DropDown";

const ChecklistHeader = () => {
  const { idProyecto, proyecto } = useProyecto();
  const { clearModal, modalComponent } = useContext(ModalContext);
  const {
    mode,
    query,
    estado,
    setQuery,
    expandAll,
    setEstado,
    idChecklist,
    setExpandAll,
  } = useChecklists();

  const handleCreateSection = () => {
    modalComponent(
      "Agregar Sección",
      <ChecklistSectionForm
        idProyecto={idProyecto}
        idChecklist={idChecklist}
        handleCancel={clearModal}
      />
    );
  };

  const renderAddSectionBtn = () => {
    if (isCoordinador(proyecto)) {
      return (
        <button onClick={handleCreateSection} className="btn btn-primary">
          <i className="fa fa-plus"></i>
        </button>
      );
    }
  };

  const renderRevisionesDropdown = () => {
    if (mode === "check") {
      return (
        <div className="col-6">
          <ChecklistsRevisions idChecklist={idChecklist} />
        </div>
      );
    }
  };

  return (
    <div className="row align-items-center mb-3 px-0">
      <div className="col-12 col-md-8 ps-0">
        <div className="row">
          <div className="col-2 col-md-1 mb-3 mb-md-0">
            <button
              onClick={() => setExpandAll(!expandAll)}
              className="btn btn-primary"
            >
              <i className={`fa fa-chevron-${expandAll ? "up" : "down"}`}></i>
            </button>
          </div>
          <div className="col-8 col-md-10">
            <input
              type="text"
              value={query}
              className="form-control"
              placeholder="Buscar sección o item"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="col-2 col-md-1 px-0 text-end">
            {renderAddSectionBtn()}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 px-0">
        <div className="row">
          <div className={`${mode === "check" ? "col-6" : "col-12"}`}>
            <DropDown
              title="Filtrar"
              selected={estado}
              modifier={setEstado}
              items={itemStatus}
            ></DropDown>
          </div>
          {renderRevisionesDropdown()}
        </div>
      </div>
    </div>
  );
};

export default ChecklistHeader;
