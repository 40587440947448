import {
  SHOW_ALERT,
  CLEAR_ALERT,
  SHOW_SUCCESS,
  CLEAR_SUCCESS,
} from "../actions/types";
import moment from "moment";
import * as XLSX from "xlsx";

export const S3_ENDPOINT = "https://arqvisor.s3.us-west-1.amazonaws.com";

export const FILES_ENDPOINT = `https://arqvisor.s3.us-west-1.amazonaws.com/files/adjuntos`;

export function formatMonto(monto) {
  monto = parseFloat(monto);
  if (!monto || monto === null || isNaN(monto)) monto = 0;
  monto = monto.toFixed(2);
  return numberWithCommas(monto);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convert = (picture, tipo) => {
  const base64 = btoa(
    new Uint8Array(picture).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  return `data:image/${tipo ? tipo : "jpg"};base64,${base64}`;
};

export function getError(error) {
  if (error.response) {
    if (error.response.status) {
      if (error.response.status === 401) {
        return "No tienes permiso para ver esta obra.";
      }
    }
  }
  return (error = error.toString());
}

export function displayError(dispatch, error) {
  if (typeof error === "object") {
    error = getError(error);
  }
  dispatch({ type: SHOW_ALERT, payload: error });
  setTimeout(() => dispatch({ type: CLEAR_ALERT }), 5000);
}

export function displaySuccess(dispatch, message) {
  dispatch({ type: SHOW_SUCCESS, payload: message });
  setTimeout(() => dispatch({ type: CLEAR_SUCCESS }), 5000);
}

export function search(elements, query) {
  if (!elements || elements === null) return elements;
  if (!query || query === null || query === "") return elements;
  query = query.toLowerCase();
  return elements.filter((element) => {
    let matching = Object.keys(element).map((column) => {
      let value = String(element[column]).toLowerCase();
      if (value.startsWith(query) || value.includes(query)) return element;
      return null;
    });
    matching = matching.filter((match) => match !== null);
    if (matching.length > 0) return true;
    return false;
  });
}

export function procesarConceptos(conceptos) {
  let conceptosNaN = conceptos.filter((concepto) => isNaN(concepto.idConcepto));
  let update = conceptos.filter((concepto) => !isNaN(concepto.idConcepto));
  let toDelete = conceptosNaN.filter((concepto) => String(concepto)[0] === "d");
  toDelete = toDelete.map((concepto) => ({
    ...concepto,
    idConcepto: parseInt(concepto.idConcepto.replace("delete-", "")),
  }));
  let insert = conceptosNaN.filter(
    (concepto) => String(concepto.idConcepto)[0] === "n"
  );
  return { update, insert, toDelete };
}

export function procesarPartidasContrato(partidas) {
  let partidasNaN = partidas.filter((partida) =>
    isNaN(partida.idPartidaContrato)
  );
  let update = partidas.filter((partida) => !isNaN(partida.idPartidaContrato));
  let toDelete = partidasNaN.filter(
    (partida) => String(partida.idPartidaContrato)[0] === "d"
  );
  toDelete = toDelete.map((partida) => ({
    ...partida,
    idPartidaContrato: parseInt(
      partida.idPartidaContrato.replace("delete-", "")
    ),
  }));
  let insert = partidasNaN.filter(
    (partida) => String(partida.idPartidaContrato)[0] === "n"
  );
  return { update, insert, toDelete };
}

export function procesarConceptosEstimacion(conceptos) {
  let conceptosExtras = conceptos.filter(
    (concepto) => concepto.idConceptoExtra
  );
  let updateExtra = conceptosExtras.filter((concepto) => !concepto.idEstimado);
  let insertExtra = conceptosExtras.filter(
    (concepto) => String(concepto.idEstimado)[0] === "n"
  );
  let conceptosEstimados = conceptos.filter(
    (concepto) =>
      concepto.estimado !== null &&
      concepto.estimado > 0 &&
      concepto.estimado !== ""
  );
  let update = conceptosEstimados.filter((concepto) => !concepto.idEstimado);
  let insert = conceptosEstimados.filter(
    (concepto) => String(concepto.idEstimado)[0] === "n"
  );
  return { update, updateExtra, insert, insertExtra };
}

export function procesarConceptosExtrasAutorizados(conceptos) {
  let updateExtra = conceptos.filter((concepto) => !concepto.idAutorizado);
  let insertExtra = conceptos.filter(
    (concepto) => String(concepto.idEstimado)[0] === "n"
  );
  return { updateExtra, insertExtra };
}

export function procesarConceptosAutorizados(conceptos) {
  let conceptosAutorizados = conceptos.filter(
    (concepto) =>
      concepto.autorizado !== undefined && concepto.autorizado !== null
  );
  let update = conceptosAutorizados.filter(
    (concepto) => !concepto.idAutorizado
  );
  let insert = conceptosAutorizados.filter(
    (concepto) => String(concepto.idAutorizado)[0] === "n"
  );
  return { update, insert };
}

export function procesarConceptosExtras(conceptos) {
  let conceptosNaN = conceptos.filter((concepto) =>
    isNaN(concepto.idConceptoExtra)
  );
  let update = conceptos.filter((concepto) => !isNaN(concepto.idConceptoExtra));
  let toDelete = conceptosNaN.filter(
    (concepto) => String(concepto.idConceptoExtra)[0] === "d"
  );
  toDelete = toDelete.map((concepto) => ({
    ...concepto,
    idConceptoExtra: parseInt(concepto.idConceptoExtra.replace("delete-", "")),
  }));
  let insert = conceptosNaN.filter(
    (concepto) => String(concepto.idConceptoExtra)[0] === "n"
  );
  return { update, insert, toDelete };
}

export function validarConceptos(conceptos) {
  conceptos = conceptos.filter(
    (concepto) => String(concepto.idConcepto)[0] !== "d"
  );
  let valido = true;
  conceptos.forEach((concepto) => {
    if (
      concepto.clave === "" ||
      concepto.nombre === "" ||
      concepto.unidad === ""
    )
      valido = false;
  });
  return valido;
}

export function getPonderacionAcumulada(rubros, idRubro) {
  let ponderacion = 0.0;
  rubros.forEach((rubro) => {
    if (String(rubro[idRubro])[0] !== "d") {
      ponderacion += parseFloat(rubro.ponderacion);
    }
  });
  return parseInt(ponderacion);
}

export function validarPorcentajeTotal(rubros, idRubro) {
  let porcentaje = getPonderacionAcumulada(rubros, idRubro);
  return porcentaje === 100;
}

export function getDiferencia(fecha_fin, fecha_inicio) {
  return moment(fecha_fin).diff(fecha_inicio, "days");
}

export function validarFechasPartidas(partidas) {
  let valido = true;
  partidas.forEach((partida) => {
    if (!partida.fecha_inicio) valido = false;
    if (!partida.fecha_fin) valido = false;
    if (partida.fecha_inicio && partida.fecha_fin) {
      let { fecha_fin, fecha_inicio } = partida;
      if (getDiferencia(fecha_fin, fecha_inicio) < 0) valido = false;
    }
  });
  return valido;
}

export function formatPartidas(partidas) {
  return partidas.map((partida) => ({
    ...partida,
    fecha_inicio: moment(partida.fecha_inicio).format("DD MMM YYYY"),
    fecha_fin: moment(partida.fecha_fin).format("DD MMM YYYY"),
  }));
}

export const isSameDate = (prev, current) => {
  return moment(prev).format("YYYY-MM-DD") === moment(current).format("YYYY-MM-DD");
};

export const hideModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  }
};

export const showModal = () => {
  const button = document.getElementById("main-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#modal";
    newButton.id = "main-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#modal");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.style.display = "none";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const showAdjuntoView = () => {
  const button = document.getElementById("adjunto-button");
  if (button && button !== null) {
    button.click();
  } else {
    const newButton = document.createElement("button");
    newButton.attributes.href = "#adjunto-view";
    newButton.id = "adjunto-button";
    newButton.setAttribute("data-toggle", "modal");
    newButton.setAttribute("data-target", "#adjunto-view");
    newButton.setAttribute("data-bs-toggle", "modal");
    newButton.setAttribute("data-bs-target", "#adjunto-view");
    newButton.style.visibility = "hidden";
    document.body.appendChild(newButton);
    newButton.click();
  }
};

export const hideAdjuntoView = () => {
  const button = document.getElementById("adjunto-button");
  if (button && button !== null) {
    button.click();
  }
};

export const getEstadoProyecto = (proyecto) => {
  return proyecto.real < proyecto.programado
    ? proyecto.programado - proyecto.real > 0.05
      ? 3
      : 2
    : 1;
};

export const stopPropagation = (e) => {
  e.stopPropagation();
};

export const getArgs = (args) => {
  if (args && args !== null) {
    const array = Object.keys(args)
      .map((key) => {
        if (args[key] && args[key] !== null && args[key] !== "") {
          return `${key}=${args[key]}`;
        }
        return null;
      })
      .filter((arg) => arg !== null);
    if (array.length > 0) {
      return `&${array.join("&")}`;
    }
  }
  return "";
};



export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function get_header_row(sheet) {
  var headers = [];
  var range = XLSX.utils.decode_range(sheet["!ref"]);
  var C,
    R = range.s.r; /* start in the first row */
  /* walk every column in the range */
  for (C = range.s.c; C <= range.e.c; ++C) {
    var cell =
      sheet[
      XLSX.utils.encode_cell({ c: C, r: R })
      ]; /* find the cell in the first row */

    var hdr = "UNKNOWN " + C; // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

    headers.push(hdr);
  }
  return headers;
}

const findHeaderRow = (rows) => {
  let headerIndex = null;
  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];
    // eslint-disable-next-line no-loop-func
    Object.keys(row).forEach((key) => {
      if (String(row[key]).toLowerCase() === "clave") {
        headerIndex = i;
      }
    });
    if (headerIndex !== null) {
      break;
    }
  }
  return headerIndex;
};

export const getPartidasFromWorkbook = (
  workbook,
  sheetName,
  partidasFields,
  conceptosFields
) => {
  const conceptosHeaders = conceptosFields.map((field) => field.selected);
  let parsed = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
    header: conceptosHeaders,
  });
  parsed = parsed.map((row, index) => ({ ...row, index }));
  /*
  let parsedEtiquetas = parsed.filter(
    (row) =>
      row.clave === undefined &&
      typeof row.CONCEPTO === "string" &&
      Object.keys(row).length === 2
  );
  console.log(parsedEtiquetas);
  */
  let parsedPartidas = parsed.filter(
    (row) => Object.keys(row).length < conceptosHeaders.length
  );

  partidasFields.forEach((field) => {
    parsedPartidas = parsedPartidas.map((partida) => {
      partida[field.target] = partida[field.selected];
      delete partida[field.selected];
      return { ...partida };
    });
  });
  parsedPartidas = parsedPartidas.map((partida) => {
    let { fecha_fin, fecha_inicio } = partida;
    if (fecha_inicio && fecha_inicio !== null) {
      console.log(fecha_inicio);
      fecha_inicio = formatExcelDateToMoment(fecha_inicio);
      console.log(fecha_inicio);
    }
    if (fecha_fin && fecha_fin !== null) {
      fecha_fin = formatExcelDateToMoment(fecha_fin);
    }
    return { ...partida, fecha_fin, fecha_inicio };
  });
  parsedPartidas = parsedPartidas.map((partida) => ({
    ...partida,
    cronograma: true,
    idPartidaContrato: partida.clave,
  }));
  parsedPartidas = parsedPartidas.filter(
    (partida) => partida.idPartidaContrato !== undefined
  );
  parsedPartidas = parsedPartidas.filter(
    (partida) =>
      String(parseInt(partida.idPartidaContrato)) ===
      String(partida.idPartidaContrato)
  );
  return parsedPartidas;
};

export const getConceptosFromWorkbook = (
  workbook,
  sheetName,
  partidas,
  conceptosFields
) => {
  const conceptosHeaders = conceptosFields.map((field) => field.selected);
  const parsed = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
    header: conceptosHeaders,
  });
  let parsedConceptos = parsed.filter(
    (row) => Object.keys(row).length >= conceptosHeaders.length - 2
  );
  conceptosFields.forEach((field) => {
    parsedConceptos = parsedConceptos.map((concepto) => {
      concepto[field.target] = concepto[field.selected];
      delete concepto[field.selected];
      return concepto;
    });
  });
  parsedConceptos = parsedConceptos.map((concepto) => {
    let { fecha_fin, fecha_inicio } = concepto;
    if (fecha_inicio && fecha_inicio !== null) {
      fecha_inicio = formatExcelDateToMoment(fecha_inicio);
    }
    if (fecha_fin && fecha_fin !== null) {
      fecha_fin = formatExcelDateToMoment(fecha_fin);
    }
    return { ...concepto, fecha_fin, fecha_inicio };
  });
  parsedConceptos = parsedConceptos.map((concepto) => ({
    ...concepto,
    cronograma: true,
    idConcepto: concepto.clave,
  }));
  parsedConceptos = parsedConceptos.filter(
    (concepto) =>
      concepto.idConcepto !== undefined &&
      String(concepto.idConcepto).includes(".")
  );
  let conceptosResult = [];
  partidas.forEach((partida) => {
    let conceptosPartida = parsedConceptos.filter(
      (concepto) =>
        String(concepto.clave).split(".")[0] === String(partida.clave)
    );
    conceptosPartida.forEach((concepto) => {
      concepto.idPartida = partida.idPartida;
      concepto.idPartidaContrato = partida.idPartidaContrato;
      conceptosResult.push(concepto);
    });
  });
  const conceptosIDSet = new Set();
  conceptosResult.forEach((concepto) => {
    conceptosIDSet.add(concepto.idConcepto);
  });
  let finalConceptosResult = [];
  conceptosIDSet.forEach((idConcepto) => {
    let current = conceptosResult.find(
      (concepto) => concepto.idConcepto === idConcepto
    );
    if (current) {
      finalConceptosResult.push(current);
    }
  });
  return finalConceptosResult;
};

export const getConceptosEstimacionFromWorkbook = (
  workbook,
  sheetName,
  conceptosFields
) => {
  const claveField = conceptosFields[0];
  const cantidadField = conceptosFields[1];
  const diff = parseInt(cantidadField.index) - parseInt(claveField.index) - 1;
  const conceptosHeaders = [claveField.selected];
  for (let i = 0; i < diff; i++) {
    conceptosHeaders.push(`col_${i}`);
  }
  conceptosHeaders.push(cantidadField.selected);
  const parsed = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
    header: conceptosHeaders,
  });
  console.log(parsed);
  let parsedConceptos = parsed.filter(
    (row) => Object.keys(row).length >= conceptosHeaders.length - 2
  );
  console.log(parsedConceptos);
  conceptosFields.forEach((field) => {
    parsedConceptos = parsedConceptos.map((concepto) => {
      concepto[field.target] = concepto[field.selected];
      delete concepto[field.selected];
      return concepto;
    });
  });
  parsedConceptos = parsedConceptos.filter(
    (concepto) =>
      !isNaN(parseFloat(concepto.clave)) &&
      String(concepto.clave).split(".").length > 1 &&
      parseFloat(concepto.estimado) > 0
  );
  const conceptosIDSet = new Set();
  parsedConceptos.forEach((concepto) => {
    conceptosIDSet.add(concepto.clave);
  });
  let finalConceptosResult = [];
  conceptosIDSet.forEach((clave) => {
    let current = parsedConceptos.find((concepto) => concepto.clave === clave);
    if (current) {
      finalConceptosResult.push(current);
    }
  });
  return finalConceptosResult;
};

export const getSheetHeaders = (workbook, sheetName) => {
  const parsed = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
  let index = findHeaderRow(parsed);
  let headerRow = parsed[index];
  headerRow = Object.keys(headerRow).map((key) => headerRow[key]);
  return headerRow;
};

export const getValue = (object, key, type) => {
  if (object && object !== null) {
    const value = object[key];
    if (value && value !== null && value !== "") {
      if (type === "boolean") {
        return value === true || parseInt(value) === 1;
      } else if (type === "date") {
        return moment(value).utc().format("YYYY-MM-DD");
      }
      return value;
    }
  }
  if (type === "boolean") return false;
  return "";
};

export function isInt(value) {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) === value &&
    !isNaN(parseInt(value, 10))
  );
}

export const formatExcelDateToMoment = (value) => {
  const date0 = new Date(0);
  const utcOffset = date0.getTimezoneOffset();
  const cellValue = new Date(0, 0, value - 1, 0, -utcOffset, 0);
  return moment(cellValue).utc().format("YYYY-MM-DD");
};
