import React, { useContext, useEffect, useState } from "react";
import ListaUsuarios from "../components/usuarios/ListaUsuarios";
import UsuarioForm from "../components/usuarios/UsuarioForm";
import { CapacitorContext } from "../context/CapacitorContext";
import { MenuContext } from "../context/MenuContext";
import { ModalContext } from "../context/ModalContext";
import { UsuariosContext } from "../context/UsuariosContext";
import TabOptions from "../components/common/TabOptions";
import { isCoordinador } from "../utils/permisos";
import { AuthContext } from "../context/AuthContext";

const Usuarios = () => {
  const { selectTabs, setButtons, setSelected } = useContext(MenuContext);
  const { platform } = useContext(CapacitorContext);
  const { user } = useContext(AuthContext);

  const [query, setQuery] = useState("");
  const [isTableView, setIsTableView] = useState(platform === "web");

  const {
    users,
    getUsers,
    editUsuario,
    clearUsuario,
    deleteUsuario,
    createUsuario,
  } = useContext(UsuariosContext);

  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getUsers();
    selectTabs();
    setSelected({ title: "usuarios" });

    if (isCoordinador(user) && platform !== "web") {
      setButtons(
        <button className="btn btn-primary" onClick={handleCreateUsuario}>
          <i className="fa fa-plus"></i>
        </button>
      );
    }

    return () => {
      clearUsuario();
      setButtons(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    clearUsuario();
  };

  const handleEditUsuario = (usuario) => {
    editUsuario(usuario);
    modalComponent(
      "Editar Usuario",
      <UsuarioForm handleCancel={handleCancel} title={"Editar Usuario"} />
    );
  };

  const handleCreateUsuario = () => {
    createUsuario();
    modalComponent(
      "Crear Usuario",
      <UsuarioForm handleCancel={handleCancel} />
    );
  };

  const handleDeleteUsuario = (usuario) => {
    modalComponent(
      "Precaución",
      <div className="container-fluid px-0">
        <p>
          ¿Eliminar el usuario {usuario.correo}? Esta acción NO puede
          deshacerse.
        </p>
        <div className="row">
  
          <div className="col-6 text-end ps-0">
            <button className="btn w-100 btn-link text-muted" onClick={clearModal}>
              Cancelar
            </button>
          </div>

          <div className="col-6 pe-0">
            <button
              className="btn btn-danger w-100"
              onClick={() => deleteUsuario(usuario.uid, getUsers)}
            >
              Eliminar
            </button>
          </div>

        </div>
      </div>
    );
  };

  const renderUsuarios = () => {
    let currentUsers = users;

    if (query && query !== "") {
      let currentQuery = String(query).toLowerCase();
      currentUsers = users.filter((user) => {
        let nombre = String(user.nombre).toLowerCase();
        let correo = String(user.correo).toLowerCase();
        return nombre.includes(currentQuery) || correo.includes(currentQuery);
      });
    }
    return (
      <ListaUsuarios
        query={query}
        platform={platform}
        users={currentUsers}
        isTableView={isTableView}
        editUsuario={handleEditUsuario}
        deleteUsuario={handleDeleteUsuario}
      />
    );
  };

  const renderContent = () => {
    if (platform === "web") {
      return (
        <div className="container-fluid h-100" style={{ overflowY: "auto" }}>
          <TabOptions
            query={query}
            setQuery={setQuery}
            isTableView={isTableView}
            setIsTableView={setIsTableView}
            handleCreate={handleCreateUsuario}
            buttonCreateText={"+ NUEVO USUARIO"}
            searchInputPlaceholder={"Buscar usuario..."}
          />

          {renderUsuarios()}
        </div>
      );
    } else {
      return (
        <div className="container-fluid mb-5 px-0 view-container">
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <input
                type="text"
                value={query}
                className="form-control bg-white"
                placeholder="Buscar un usuario..."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">{renderUsuarios()}</div>
          </div>
        </div>
      );
    }
  };

  return renderContent();
};

export default Usuarios;
