import React, { useState, useEffect, useContext } from "react";
import { FolderContext } from "../../context/FolderContext";
import { ModalContext } from "../../context/ModalContext";
import { ProyectosContext } from "../../context/ProyectosContext";

const FolderForm = ({ idFolder, initialValue, idPadre, handleCallback }) => {
  const [value, setValue] = useState("");

  const { getFolder, setFolderName, postSubFolder } = useContext(FolderContext);

  const { proyecto } = useContext(ProyectosContext);

  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    if (initialValue && initialValue !== null) {
      setValue(initialValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    clearModal();
    getFolder(proyecto.idProyecto, idPadre);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { idProyecto } = proyecto;
    postSubFolder(idProyecto, idPadre, value, handleCallback);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid px-0">
        <label>Escribe un nombre de carpeta</label>
        <input
          type="text"
          args="nuevo"
          value={value}
          maxLength={140}
          className="form-control mb-0"
          placeholder="Ejemplo: Fotos 28 Nov"
          onChange={(e) => {
            setValue(e.target.value);
            setFolderName(idFolder, e.target.value);
          }}
        />
        <div className="row mt-3">
         
          <div className="col-6 ps-0 text-end">
            <button
              type="button"
              onClick={handleCancel}
              className="btn btn-link w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6 pe-0">
            <button className="btn w-100 btn-primary" type="submit">
              Guardar
            </button>
          </div>

        </div>
      </div>
    </form>
  );
};

export default FolderForm;
