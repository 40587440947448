import React from "react";
import SearchableSelect from "../global/SearchableSelect";
import ConceptosTable from "./ConceptosTable";

const CategoriaConceptos = ({ idCategoria, conceptos, titulo, items }) => {
  const conceptosCategoria = conceptos?.filter(
    (concepto) => parseInt(concepto.idCategoria) === parseInt(idCategoria)
  );

  return (
    <div className="card p-3 shadow mb-3">
      <div className="row mb-3">
        <div className="col-12 col-md-6">
          <h4 className="mb-0">{titulo}</h4>
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-9 col-sm-10 col-xl-10">
              <SearchableSelect items={items} />
            </div>
            <div className="col-3 col-sm-2 col-xl-2">
              <button className="btn w-100 btn-primary">
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConceptosTable conceptos={conceptosCategoria} />
    </div>
  );
};

export default CategoriaConceptos;
