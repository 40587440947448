import React, { useContext, useEffect } from "react";
//import GraficaFinanzas from "../components/finanzas/GraficaFinanzas";
import Contratos from "../components/finanzas/Contratos";
import { ProyectosContext } from "../context/ProyectosContext";
import { MenuContext } from "../context/MenuContext";
import { CapacitorContext } from "../context/CapacitorContext";

const Finanzas = ({ idProyecto }) => {
  const { setSelected } = useContext(MenuContext);
  const { proyecto, getSingleProyecto } = useContext(ProyectosContext);
  const { platform } = useContext(CapacitorContext);

  useEffect(() => {
    getSingleProyecto(idProyecto);
    setSelected({ title: "finanzas" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (proyecto && proyecto !== null) {
      return <Contratos idProyecto={idProyecto} />;
    }
  };

  return <div className={`container-fluid ${platform !== 'web' && 'px-0'}`}>{renderContent()}</div>;
};

export default Finanzas;
